<template>    
    <div class="content-wrapper">
        
        <div v-if="isList" class="content pt-0">
            <bo-page-title />
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>
                                                        
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                                            </b-form-group>
                                        </div>
                                        
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_ranap"  :options="Config.mr.statusRanap" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                     
                
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>NO. KEDATANGAN</th>
                                <th>TANGGAL MASUK</th>
                                <th>NAMA/NO KTP</th>
                                <th>DOKTER DPJP</th>                                
                                <th>NO RM</th>
                                <th>CARA BAYAR</th>
                                <th>TANGGAL LAHIR</th>
                                <th>JENIS RAWAT INAP</th>
                                <th>USIA</th>
                                <th>RUANG & KAMAR</th>
                                <th>STATUS</th>
                                <th>NAMA ADMISI</th>
                                <th>ASAL PASIEN</th>
                                <th>AKSI</th>
                            </tr>
                        </thead>
                        
                        <tbody v-if="(dataList||[]).length">
                            <tr v-for="(v,k) in (dataList||[])" :key="k">
                                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                <td>
                                    <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                                </td>
                                
                                <td>
                                    <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                                    <div>{{v.aranres_jam_periksa||"-"}} WIB</div>
                                </td>
                                
                                <td>
                                    <p :class="isSameName(v.ap_fullname)+' mb-0'">
                                    {{uppercaseWord(v.ap_fullname)||"-"}}
                                    </p>
                                    <br/>
                                    <small class="text-danger" style="font-size: 10px;" v-if="v.ap_fullname && isSameName(v.ap_fullname)">Nama Pasien Sama</small>

                                    <div class="text-info">{{v.ap_nik||"-"}}</div>
                                    <span v-if="v.aranr_is_emergency == 'Y'" :class="`badge bg-danger mt-2`">Emergency</span>
                                </td>
                                   
                                <td>
                                    <div class="font-weight-semibold">{{v.bu_full_name||"-"}}</div>
                                </td>

                                <td>
                                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                    <small :class="v.ap_is_old_pasien == 'Y' ? 'font-weight-semibold text-danger' : 'font-weight-semibold text-primary'" style="font-size: 12px;">
                                    {{v.ap_is_old_pasien == 'Y' ? 'PASIEN LAMA' : 'PASIEN BARU'}}</small>
                                </td>

                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.aranres_jaminan)||"-"}}</div>
                                </td>
                                     
                                <td>
                                    <div v-if="v.ap_dob" class="font-weight-semibold">
                                        {{v.ap_dob | moment("DD MMM YYYY")}}
                                    </div>
                                    <span v-else> - </span>
                                </td>

                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                                </td>
                            

                                <td>
                                    <div class="font-weight-semibold">
                                        {{v.ap_usia_with_ket || "-"}}
                                    </div>
                                </td>
                                
                                <td>
                                    <template v-if="v.mranb_name && v.mrank_name">
                                        <div class="font-weight-semibold">{{v.mranb_name || "-"}}</div>
                                        <span class="text-secondary">{{v.mrank_name || "-"}}</span>
                                    </template>
                                    <span v-else> - </span>
                                </td>

                                <td>
                                
                                    <div class="badge badge-dark mb-1" v-if="v.aranr_is_void == 'Y'">
                                        VOID
                                    </div>
                                    <template v-else>
                                        <div v-if="!v.aranr_status" :class="`badge badge bg-warning`">Pendaftaran Baru</div>
                                        
                                        <template v-else>
                                            <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>
                                        </template>
                                        <!--
                                        <template>
                                            <div v-if="v.aranr_is_pindah_ruang == 'Y'" class="badge badge-info mb-1" v-b-tooltip.hover.right 
                                            :title="v.aranr_is_pindah_by == 'ADMISI'?'Pindah Ruang Dilakukan Oleh Admisi':'Pindah Ruang Dilakukan Oleh Admin Ruang'">
                                                Pindah Ruang
                                            </div>
                                        </template>

                                        <div class="badge badge-warning mb-1" v-if="!v.absd_no_sep && (v.aranres_jaminan == 3)"> Buatkan SEP </div>  
                                        -->

                                    </template>
                                

                                </td>
                                <td>
                                    {{v.nama_admisi||"-"}}
                                </td>
                                
                                
                                <td>
                                    <span v-if="v.aranres_cara_masuk == 'RAJAL'">Rawat Jalan</span>
                                    <span v-else-if="v.aranres_cara_masuk == 'UGD'">UGD</span>
                                    <span v-else-if="v.aranres_cara_masuk == 'BBL'">Bayi Baru Lahir</span>
                                    <span v-else>LAINNYA</span>

                                    <div class="font-weight-semibold mt-1 text-info" v-b-tooltip.hover.right 
                                    :title="v.aranres_cara_masuk == 'UGD'?'Nomor Registrasi UGD':'Nomor Registrasi Rawat Jalan'">{{v.no_reg||"-"}}</div>
                                </td>

                                <td>
                                    <!--
                                    <template v-if="v.aranres_is_draft !== 'Y'">
                                        <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                        v-if="moduleRole('view') && v.aranres_is_draft !== 'Y'"
                                        :to="{name: $route.name, params: {pageSlug: v.aranres_id}, query: {isDetail: true}}"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="View"><i class="icon-eye"></i></router-link>

                                        <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                        :to="{name: $route.name, params: {pageSlug: v.aranres_id}}"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Edit Data"><i class="icon-pencil5"></i></router-link>
                                        
                                        <a href="javascript:;" v-if="v.aranr_is_void != 'Y'" @click="voidData(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" data-popup="tooltip" data-toggle="modal" v-b-tooltip.hover.right title="Pasien VOID">
                                        <i class="icon-cancel-circle2"></i>
                                        </a>
                                        
                                        <router-link :to="{name: $route.name, params: {pageSlug: v.aranres_id}, query: {isDoc: true}}" v-if="v.aranr_is_void != 'Y'" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" data-popup="tooltip" data-toggle="modal" v-b-tooltip.hover.right title="Lengkapi Dokumen Persetujuan">
                                        <i class="icon-file-check"></i>
                                        </router-link>
                                    </template>
                                    
                                    <template v-else>
                                        <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                        v-if="moduleRole('view')"
                                        :to="{name: $route.name, params: {pageSlug: v.aranres_id}, query: {isDetail: true}}"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="View"><i class="icon-eye"></i></router-link>

                                        <a href="javascript:;" @click="toForm(v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info mt-1"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Daftarkan Pasien"><i class="icon-file-plus"></i></a>

                                        <a href="javascript:;" @click="doDelete(k,v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-danger border-danger mt-1"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Hapus Data Pendaftaran"><i class="icon-bin"></i></a>
                                    </template>
                                    -->


                                    <template v-if="!v.aranr_status && moduleRole('edit')">
                                        <a href="javascript:;" @click="changeJadwal(k,v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info mt-1"
                                        data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Ubah Data Penjadwalan"><i class="icon-calendar"></i></a>
                                    </template>

                                    <router-link :to="{name: 'RanapReservasi', params: {pageSlug: v.aranres_id}, query: {isDoc: true}}" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800 mt-1" data-popup="tooltip" data-toggle="modal" v-b-tooltip.hover.right title="Lengkapi Dokumen Persetujuan">
                                    <i class="icon-file-check"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>

            </div>


            <validation-observer ref="VFormSubmitEdit">
              <b-modal
                  v-model="openModalChangeJadwal"
                  @ok.prevent="submitChangeJadwal"
                  size="sm"
                  no-close-on-backdrop
                  class="text-center"
                  :title="'Ubah Jadwal Rawat Inap'" 
                >
                <div class="row">
                  <div class="form-group col-md-12">
                      <label>Tanggal Rawat Inap <small class="txt_mandatory">*</small></label>
                      <div class="input-group">
                          <datepicker input-class="form-control transparent"
                              placeholder="Tanggal Rawat Inap" class="my-datepicker"
                              calendar-class="my-datepicker_calendar"
                              v-model="rowEdit.aranres_tanggal_periksa"
                              >
                          </datepicker>
                          <div class="input-group-append calendar-group">
                              <span class="input-group-text" id="basic-addon2"><i
                                      class="icon-calendar"></i></span>
                          </div>
                      </div>
                      <VValidate 
                          name="Tanggal Rawat Inap" 
                          v-model="rowEdit.aranres_tanggal_periksa" 
                          :rules="{required: 1}"
                      />
                  </div>
                  
                  <!--
                  <div class="form-group col-md-12">
                    <label>Waktu Pendaftaran <small class="txt_mandatory">*</small></label>

                    <vue-timepicker manual-input v-model="rowEdit.aranres_jam_periksa" format="HH:mm"  input-class="form-control"></vue-timepicker>

                    <VValidate 
                        name="Waktu Pendaftaran" 
                        v-model="rowEdit.aranres_jam_periksa" 
                        :rules="{required: 1}"
                    />
                  </div>
                  -->
                </div>
              </b-modal>
            </validation-observer>
        </div>

        
        <template v-else>
            <FormDetail v-if="isDetail && !isDoc" :row.sync="row" v-bind="passToSub" />
            <FormDoc v-else-if="!isDetail && isDoc" :row.sync="row" v-bind="passToSub" />
            <Form v-else :row.sync="row" v-bind="passToSub" />
        </template>
    </div>
</template>

<script>
    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')

    import Datepicker from 'vuejs-datepicker'
    // import VueTimepicker from 'vue2-timepicker'
    // import 'vue2-timepicker/dist/VueTimepicker.css'
   
    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'

    export default {
        extends: GlobalVue,
  	    components:{
          DateRangePicker, Datepicker
        //   VueTimepicker
        },
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            isDoc(){
                return this.$route.query.isDoc
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    isShowBangsal: this.isShowBangsal,
                    infoBangsal: this.infoBangsal,
                    katalogBangsal: this.katalogBangsal
                }
            },
        },
        data(){
            return {
                idKey:'aranres_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(moment().subtract(6, 'days')),
                    endDate: new Date(moment().add(6, 'days')),
                },
                pasienLama: 0,
                pasienBaru: 0,

                rowEdit : {},
                rowPasien : {},
                openModalVoid : false,


                // for form
                isShowBangsal: false,
                infoBangsal: [],
                katalogBangsal: [],
                mDPJPUtama: [],

                openModalChangeJadwal: false,
            }
        },
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'

            
            this.doConvertDate()      
            this.apiGet()
            console.log("initest ya")
            
            if(this.isList){
                this.apiGetResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            submitChangeJadwal(){
              this.$refs['VFormSubmitEdit'].validate().then(success=>{
                if(success){
                  let data = this.rowEdit
                  data.type = 'edit-rencana-ranap'
                  this.loadingOverlay = true

                  Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                  ).then(res=>{
                      this.loadingOverlay = false
                      this.openModalChangeJadwal = false

                      let resp = res.data
                      resp.statusType = 200
                      this.$swal({
                          title: resp.message,
                          icon: resp.status,
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          allowEscapeKey: false
                      })

                      this.apiGet()

                  }).catch(err=>{
                      this.loadingOverlay = false
                      if (err) {
                          err.statusType = err.status
                          err.status = "error"
                          err.title = err.response?.data?.title
                          err.message = err.response?.data?.message
                          err.messageError = err.message
                      }
                      this.doSetAlertForm(err)
                  })

                }
              })
            },
            changeJadwal(k,row){
              this.rowEdit = row
              this.rowEdit.aranres_jam_periksa = this.rowEdit.aranres_jam_periksa||''
              this.openModalChangeJadwal = true
            },
            toForm(v){
                if(!v.ap_dob || v.ap_is_draft == "Y"){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon lengkapi data pasien sebelum melanjutkan pendaftaran.',
                        showCancelButton: true,
                        confirmButtonText: 'Lengkapi Data Pasien',
                        cancelButtonText: 'Lengkapi Nanti'
                    }).then(result => {
                        if (result.value) {
                            this.$router.push({name: 'RoPasienNew', params: {pageSlug: v.ap_id}})
                            .catch(()=>{})           
                        }
                    })
                }
                this.$router.push({name: this.$route.name, params: {pageSlug: v.aranres_id}})
                .catch(()=>{})
            },

            fillDOB(e){
                let val = e.target.value
                if(val.length == 10){
                    let dobdata = val.split("/")
                    let date = moment(dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]).format('DD/MM/YYYY')
                    let d = new Date()
                    if(date == 'Invalid date'){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tanggal Tidak Valid'
                        })
                    }else{
                        this.filter.dob = dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]
                        this.doFill()
                    }
                }
            },
            isSameName(name){
                let index = this.dataList.findIndex(x=>x.ap_fullname == name)
                let count = 0
                for (let i = 0; i < this.dataList.length; i++) {
                    if (this.dataList[i].ap_fullname === name) {
                        count++
                    }
                }
                if(count > 1){
                    return "text-warning"
                }else{
                    return ""
                }
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                  startDate: new Date(moment().subtract(6, 'days')),
                  endDate: new Date(moment().add(6, 'days')),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            toDetail(data){
                this.$router.push({ name: 'RanapReservasi', params: { pageSlug: data.aranres_id } })
                .catch(()=>{})
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            voidData(row){
                this.rowEdit = row
                this.openModalVoid = true
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                if(this.isList){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            },
            'openModalChangeJadwal'(v){
              if(v == false){
                this.apiGet()
              }
            }
        }
    }
</script>